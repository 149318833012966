import React from 'react'
import * as Sentry from '@sentry/react';
import Loader from '../Routes/Loader';
import { RequireApi } from './AuthContext';

export const ConfigContext = React.createContext();

export const useConfig = () => {
  return React.useContext(ConfigContext);
}

export const RequireConfig = ({ children }) => {
  let config = useConfig();

  if (!config.ready) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <RequireApi><Loader /></RequireApi>;
  }

  return children;
}

export const ConfigProvider = props => {
  const [ready, setReady] = React.useState(false);
  const [config, setConfig] = React.useState();

  React.useEffect(() => {
    if (config) Sentry.setExtras({ configUpdatedAt: config.updatedAt });
  }, [config]);

  return (
    <ConfigContext.Provider value={{ config, setConfig, ready, setReady }}>
      {props.children}
    </ConfigContext.Provider>
  )
}
