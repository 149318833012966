import React from 'react';
import * as Sentry from "@sentry/react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';

import { ConfigProvider, RequireConfig } from './Contexts/ConfigContext';
import { AuthProvider, RequireAuth } from './Contexts/AuthContext';
import Loading from './Components/Loading';
import { DataListProvider } from './Contexts/DataListContext';
import { ModalProvider } from './Contexts/ModalContext';

const Loader = React.lazy(() => import('./Routes/Loader'));
const Register = React.lazy(() => import('./Routes/Register'));
const RegisterScanner = React.lazy(() => import('./Routes/Register/RegisterScanner'));
const RegisterCamera = React.lazy(() => import('./Routes/Register/RegisterCamera'));
const RegisterManual = React.lazy(() => import('./Routes/Register/RegisterManual'));
const Login = React.lazy(() => import('./Routes/Login'));
const Jobs = React.lazy(() => import('./Routes/Jobs'));
const Job = React.lazy(() => import('./Routes/Job'));
const Damage = React.lazy(() => import('./Routes/Damage'));
const Asset = React.lazy(() => import('./Routes/Asset'));
const AssetNew = React.lazy(() => import('./Routes/AssetNew'));
const PhotoNew = React.lazy(() => import('./Routes/PhotoNew'));
const Photos = React.lazy(() => import('./Routes/Photos'));
const DamageNew = React.lazy(() => import('./Routes/DamageNew'));
const Damages = React.lazy(() => import('./Routes/Damages'));
const Settings = React.lazy(() => import('./Routes/Settings'));

function App() {

  return (
    <AuthProvider>
      <ConfigProvider>
        <DataListProvider>
          <ModalProvider>
            {/* <Routes location={location.state?.backgroundLocation || location}> */}
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to='jobs' />} />
                <Route path="settings" element={<React.Suspense fallback={<Loading />}><Settings /></React.Suspense>} />
                <Route path="register" element={<React.Suspense fallback={<Loading />}><Register /></React.Suspense>}>
                  <Route index element={<React.Suspense fallback={<Loading />}><RegisterScanner /></React.Suspense>} />
                  <Route path="camera" element={<React.Suspense fallback={<Loading />}><RegisterCamera /></React.Suspense>} />
                  <Route path="manual" element={<React.Suspense fallback={<Loading />}><RegisterManual /></React.Suspense>} />
                </Route>
                <Route path="login" element={<RequireConfig><React.Suspense fallback={<Loading />}><Login /></React.Suspense></RequireConfig>} />
                <Route path="jobs" element={<RequireAuth><div className='my-4 mx-2'><Outlet /></div></RequireAuth>}>
                  <Route index element={<React.Suspense fallback={<Loading />}><Jobs /></React.Suspense>} />
                  <Route path=":jobId" element={<Outlet />}>
                    <Route index element={<React.Suspense fallback={<Loading />}><Job /></React.Suspense>} />
                    <Route path="assets" element={<Outlet />}>
                      <Route index element={<React.Suspense fallback={<Loading />}><Job /></React.Suspense>} />
                      <Route path="new" element={<React.Suspense fallback={<Loading />}><AssetNew /></React.Suspense>} />
                      <Route path=":assetId" element={<Outlet />}>
                        <Route index element={<React.Suspense fallback={<Loading />}><Asset /></React.Suspense>} />
                        <Route path="photos" element={<Outlet />}>
                          <Route index element={<React.Suspense fallback={<Loading />}><Photos /></React.Suspense>} />
                          <Route path="new" element={<React.Suspense fallback={<Loading />}><PhotoNew /></React.Suspense>} />
                          <Route path=":photoId" element={<>temp</>} />
                        </Route>
                        <Route path="damages" element={<Outlet />}>
                          <Route index element={<React.Suspense fallback={<Loading />}><Damages /></React.Suspense>} />
                          <Route path="new" element={<React.Suspense fallback={<Loading />}><DamageNew /></React.Suspense>} />
                          <Route path=":damageId" element={<Outlet />}>
                            <Route index element={<React.Suspense fallback={<Loading />}><Damage /></React.Suspense>} />
                            <Route path="new-photos" element={<React.Suspense fallback={<Loading />}><PhotoNew /></React.Suspense>} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ModalProvider>
        </DataListProvider>
      </ConfigProvider>
    </AuthProvider >
  );
}

function Layout() {
  const [loading, setLoading] = React.useState(true);

  return (
    <div className='max-w-screen-sm m-auto relative border bg-slate-200 min-h-screen'>
      {loading ? <React.Suspense fallback={<Loading />}><Loader onLoaded={() => setLoading(false)} /></React.Suspense> : <Outlet />}
    </div>
  );
}

export default Sentry.withProfiler(App);
