import Dexie from 'dexie';

const db = new Dexie('AssetBison');

// Declare tables, IDs and indexes
db.version(5).stores({
  settings: 'key',
  users: 'id,name',
  jobs: '++id,date,_uploadStatus,type',
  assets: '++id,date,job,identification,type',
  photos: '++id,date,job,asset,type,_uploadStatus,_bundleId',
  damages: '++id,date,job,asset,type',
});

export const settings = db.table('settings');
export const users = db.table('users');
export const jobs = db.table('jobs');
export const assets = db.table('assets');
export const photos = db.table('photos');
export const damages = db.table('damages');

export default db;
