import React from 'react'
import * as Sentry from '@sentry/react';
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { isMobile, mobileVendor, mobileModel, osName, osVersion } from 'react-device-detect';
import md5 from 'blueimp-md5';

import { settings, users } from '../Includes/db';
import ApiService from '../Services/ApiService';

const AuthContext = React.createContext();

export default AuthContext;

export const useAuth = () => {
  return React.useContext(AuthContext);
}

export const AuthStatus = () => {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Logged in as {auth.user.name}!{' '}
      <button
        onClick={() => {
          auth.logout(() => navigate("/"));
        }}
        className='underline'
      >
        Sign out
      </button>
    </p>
  );
}

export const RequireApi = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/register" state={{ from: location }} replace />;
  }

  return children;
}

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export const AuthProvider = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const [ready, setReady] = React.useState(false);
  const [apiUrl, setApiUrl] = React.useState();
  const [uniqueId, setUniqueId] = React.useState();
  const [token, setToken] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const getSettings = async () => {
      const [token, apiUrl, uniqueId] = await settings.bulkGet(['token', 'apiUrl', 'uniqueId']);
      if (!token) {
        if (!/^\/register/.test(location.pathname)) {
          navigate('/register', { state: { from: location }, replace: true });
        }
      } else {
        setApiUrl(apiUrl.value);
        setUniqueId(uniqueId.value);
        setToken(token.value);

        Sentry.setExtras({ token, apiUrl, uniqueId });

        const sessionUserId = sessionStorage.getItem('user');
        if (sessionUserId) {
          const currentUser = await users.get(sessionUserId);
          if (currentUser) setUser({ id: currentUser.id, name: currentUser.name });
        }
      }
      setReady(true);
    };
    getSettings();
  }, [])

  React.useEffect(() => {
    if (user) {
      sessionStorage.setItem('user', user.id);
      Sentry.setExtras({ user });
      // navigate(/^\/login/.test(location.state?.from.pathname) ? location.state?.from : "/jobs", { replace: true })
    }
  }, [user])

  const login = (u, pin) => {
    if (pin.length === 5 && md5(pin) === u.pin) {
      localStorage.setItem('lastUser', u.value);
      setUser({ id: u.value, name: u.label });
      navigate(location.state?.from && !/^\/login/.test(location.state.from.pathname) ? location.state.from : "/jobs", { replace: true })
      return true;
    }
    return false;
  }

  const logout = async callback => {
    // remove auth settings and set states
    sessionStorage.removeItem('user');
    setUser('');
    if (typeof callback === 'function') callback();
  }

  const unregister = async callback => {
    // remove auth settings and set states
    await settings.bulkDelete(['token', 'uniqueId', 'apiUrl'])
    setUniqueId('');
    setToken('');
    setApiUrl('');
    setUser('');
    if (typeof callback === 'function') callback();
  }

  const handleDeviceToken = async (deviceToken, uniqueId, apiUrl) => {
    await settings.bulkPut([
      { key: 'uniqueId', value: uniqueId },
      { key: 'token', value: deviceToken },
      { key: 'apiUrl', value: apiUrl },
    ]);

    setUniqueId(uniqueId);
    setToken(deviceToken);
    setApiUrl(apiUrl);

    navigate(location.state?.from ? location.state.from : "/", { replace: true });

    return;
  }

  const registerBarcode = async ({ h, i, v }) => {
    const apiUrl = `${h}/api/v${v}`;
    const api = new ApiService(apiUrl);
    const uniqueId = `${Date.now().toString(36)}${Math.random().toString(36)}`;

    return api
      .register(i, {
        make: isMobile ? (mobileVendor ? mobileVendor : 'mobile') : 'desktop', // Make
        model: isMobile ? (mobileModel ? mobileModel : 'mobile') : 'desktop', // Model
        os: `${osName} ${osVersion}`, // OS
        serial: uniqueId, // Serial
      })
      .then(deviceToken => handleDeviceToken(deviceToken, uniqueId, apiUrl))
      .catch((error) => {
        console.error(error);
        let message = 'There was a problem connecting to the service.\n\nMake sure you\'re connected to the internet and try again.\n\nIf the problem persists, contact support';
        if (typeof error.response !== 'undefined') {
          switch (error.response.status) {
            case 404:
              message = 'Register not found or is no longer active.';
              break;
            case 429:
              message = 'Register limit has been reached, barcode limit must be increased or you will need another register barcode.';
              break;
            case 501:
              message = 'Register type not implemented for this device yet, try manual registration instead.';
              break;
            default:
              message = 'Unexpected error when trying to register, try manual registration instead.';
          }
        }
        return message;
      });
  }

  const registerLogin = async ({ username, password, url }) => {
    const regex = /^(?:https:\/\/)?([^/]+)(?:\/api\/v1)*$/;
    const cleanUrl = url.replace(regex, '$1');
    const apiUrl = `https://${cleanUrl}/api/v1`;
    const uniqueId = `${Date.now().toString(36)}${Math.random().toString(36)}`;

    const api = new ApiService(apiUrl);

    api
      .login(username, password)
      .then(() => api.getDeviceToken({
        make: isMobile ? (mobileVendor ? mobileVendor : 'mobile') : 'desktop', // Make
        model: isMobile ? (mobileModel ? mobileModel : 'mobile') : 'desktop', // Model
        os: `${osName} ${osVersion}`, // OS
        serial: uniqueId, // Serial
      }))
      .then(deviceToken => handleDeviceToken(deviceToken, uniqueId, apiUrl))
      .catch((error) => {
        if (
          typeof error.response !== 'undefined'
          && error.response.status === 401
        ) {
          throw 'Username or password is incorrect';
        } else {
          throw 'There was a problem connecting to the service.\n\nMake sure you\'re connected to the internet and try again.\n\nIf the problem persists, contact support';
        }
      });
  }

  return (
    <AuthContext.Provider value={{ user, token, login, logout, registerBarcode, registerLogin, unregister, ready, apiUrl, uniqueId }}>
      {props.children}
    </AuthContext.Provider>
  )
}
