import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { tryParseJSON } from '../Includes/Utilities';

const maxSwipeOffset = 100; // adjust the maximum right offset as needed

export const DataListContext = React.createContext();

export const useDataList = () => {
  return React.useContext(DataListContext);
};

export const DataListProvider = props => {
  const [showHidden, setShowHidden] = React.useState(false);

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    trackTouch: true,
    // onSwipedLeft: (d) => console.log('swiped left', d),
    // onSwipedRight: (d) => console.log('swiped right', d),
    onSwipeStart: (d) => {
      const config = tryParseJSON(d.event.target.dataset.swipeable);
      if (!config) return;
      // set the initial offset
      d.event.target.dataset.initialOffset = parseInt(d.event.target.style.getPropertyValue('--tw-translate-x')) || 0;
      d.event.target.dataset.clickBlock = true;
    },
    onSwiping: (d) => {
      const config = tryParseJSON(d.event.target.dataset.swipeable);
      if (!config) return;
      // get the initial offset
      let initialOffset = parseInt(d.event.target.dataset.initialOffset);
      let newOffset = initialOffset + d.deltaX;
      // clamp the offset to the +/- maxSwipeOffset
      if (newOffset > maxSwipeOffset * 1.5) newOffset = maxSwipeOffset * 1.5;
      if (newOffset < -maxSwipeOffset * 1.5) newOffset = -maxSwipeOffset * 1.5;

      if (newOffset < 0 && !config.left) newOffset = 0;
      if (newOffset > 0 && !config.right) newOffset = 0;
      // set the new --tw-translate-x value
      d.event.target.style.setProperty('--tw-translate-x', `${newOffset}px`);
    },
    onSwiped: (d) => {
      const config = tryParseJSON(d.event.target.dataset.swipeable);
      if (!config) return;
      // get the current --tw-translate-x value
      const currentOffset = parseInt(d.event.target.style.getPropertyValue('--tw-translate-x'));
      // snap to either negative maxSwipeOffset, 0 or positive maxSwipeOffset based on the direction being swiped and the current offset
      let snapTo = 0;
      if (d.dir === 'Left') {
        // if current offset is less than 0, snap to -maxSwipeOffset
        if (currentOffset < 0) snapTo = -maxSwipeOffset;
        // if current offset is greater than 0, snap to 0
        if (currentOffset > 0) snapTo = 0;
      }
      if (d.dir === 'Right') {
        // if current offset is less than 0, snap to 0
        if (currentOffset < 0) snapTo = 0;
        // if current offset is greater than 0, snap to maxSwipeOffset
        if (currentOffset > 0) snapTo = maxSwipeOffset;
      }
      // set the new --tw-translate-x value
      d.event.target.style.setProperty('--tw-translate-x', `${snapTo}px`);
      if (snapTo === 0) {
        setTimeout(() => {
          d.event.target.dataset.clickBlock = false;
        }, 100);
      }
    },
    onTap: (d) => {
      // get current offset
      const currentOffset = parseInt(d.event.target.style.getPropertyValue('--tw-translate-x'));
      if (currentOffset !== 0) {
        // snap to 0
        d.event.target.style.setProperty('--tw-translate-x', '0px');
        setTimeout(() => {
          d.event.target.dataset.clickBlock = false;
        }, 100);
      }
    },
  });

  const toggleHidden = () => {
    setShowHidden(!showHidden);
  };

  return (
    <DataListContext.Provider value={{ showHidden, toggleHidden, swipeHandlers }}>
      {props.children}
    </DataListContext.Provider>
  );

};
