export const tryParseJSON = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

export const get = (obj, path, defaultValue) => {
  if (!path) return defaultValue;
  const value = path.split('.').reduce((o, i) => o?.[i], obj);
  return typeof value !== 'undefined' && value !== null ? value : defaultValue;
};


export const formatSingleOption = o => {
  if (o.value && o.label) return o;

  if (o.key && o.value && Array.isArray(o.value)) {
    return {
      options: formatOptions(o.value),
      label: o.name ? o.name : o.key,
    };
  }

  if (o.key && o.value) {
    return { value: o.key, label: o.value };
  }

  return { value: o, label: o };
}

export const formatOptions = (field, config, job, asset) => {
  let options = field.options ? field.options : false;

  if (options === false && Array.isArray(field)) options = field;

  if (options === false && !field.lookup) return [];

  if (!field.options && field.lookup && Array.isArray(config.config.lookups?.[field.lookup])) {
    options = config.config.lookups[field.lookup];
  }

  if (field.filter) {
    let filterValue;
    const filterKey = field.filter;
    if (typeof filterKey === 'string' && filterKey.indexOf('.meta') > 0) {
      const [parent, prop] = filterKey.split('.meta.');
      switch (parent) {
        case 'Batch':
          filterValue = job?.[prop];
          break;

        case 'Inspection':
          filterValue = asset?.[prop];
          break;

        default:
          console.log(`Unexpected filter parent for "${field.filter}"`);
          break;
      }
    }
    if (filterValue) {
      options = options.filter(o => o.key === (filterValue.value ? filterValue.value : filterValue));
    }

  }

  const output = options.map(o => formatSingleOption(o));


  return output;
}

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

export const remember = async (key, value) => {
  await localStorage.setItem(`persisted_${key}`, JSON.stringify(value));
}

export const recall = async (key) => {
  const v = await localStorage.getItem(`persisted_${key}`);
  return v !== 'undefined' ? JSON.parse(v) : undefined;
}

export const timeout = (delay) => {
  return new Promise(res => setTimeout(res, delay));
}


export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
}