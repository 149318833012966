import React from 'react'

import logo from '../logo.svg';

function Loading() {
  return (
    <div className='flex-1 p-5 pb-16 relative text-center'>
      <div
        className={`inset-0 py-5 pb-10 w-full relative`}>
        <img src={logo} className="inline-block" alt="logo" />
      </div>
      <div className='w-full inset-x-5 bottom-5 text-center'>
        <div className="loader mx-auto mb-8 text-2xl" />
        <div className='uppercase font-bold text-sm'>Loading...</div>
      </div>
    </div>
  )
}

export default Loading